
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Input, Select } from '@/components/input-elements';
import { Apollo, Notify } from '@/core/services';
import { UPDATE_LANGUAGE } from '../graphql/Mutations';
import { useI18n } from 'vue-i18n';
import { GET_LOCALES } from '@/modules/common/locales/graphql/Queries';
import i18nProvider from '@/core/services/i18nProvider';
import InnerLoader from '@/components/InnerLoader.vue';

export default defineComponent({
    name: 'edit tax',
    components: {
        Input,
        Select,
        InnerLoader
    },

    setup() {
        const modal: any = ref();
        const loading = ref(false);
        const id = ref();
        const locale = ref('en');
        const locales: any = ref([]);
        const key = ref();
        const translation = ref();
        const tempValues = ref({}) as Record<any, any>;
        const emitter: any = inject('emitter');
        const system_locale = ref();
        const i18n = useI18n();

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });
        // Submit Handler Request
        const submitHandlerForm = async () => {
            loading.value = true;
            const formData = {
                id: id.value,
                translation: JSON.stringify(tempValues.value)
            };
            await Apollo.mutate({
                mutation: UPDATE_LANGUAGE,
                variables: { input: formData },
                update: (store, { data: { update_language } }) => {
                    modal.value.hide();
                    loading.value = false;
                    i18nProvider.SetLocaleMessage(system_locale.value);
                    Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                }
            }).catch(() => {
                loading.value = false;
            });
        };

        // On change input value
        const changeLang = (e: string) => {
            translation.value = tempValues.value[e].name;
        };

        // update on change value input
        const updateValue = (e: string) => {
            tempValues.value[locale.value].name = e;
        };

        const loadLang = () => {
            const locales_data = Apollo.readQuery({
                query: GET_LOCALES
            });
            locales_data.locales.forEach(element => {
                locales.value.push({
                    label: element.locale.charAt(0).toUpperCase() + element.locale.slice(1),
                    value: element.locale
                });

                tempValues.value[element.locale] = { name: '' };
            });
        };

        loadLang();

        // Reset Form Data On Model Popup
        const resetForm = () => {
            emitter.emit('clearInput');
            key.value = '';
            translation.value = '';
            locale.value = 'en';
            // empty json fields data
            for (const i of Object.getOwnPropertyNames(tempValues.value)) {
                tempValues.value[i].name = '';
            }
        };

        // Emitter To Open Model
        emitter.on('editLanguageAction', (language: Record<any, any>) => {
            resetForm();
            id.value = language.id;
            key.value = language.key;
            const json_decode_name = JSON.parse(language.translation);
            for (const i in json_decode_name) {
                i == 'en' ? (translation.value = json_decode_name[i].name) : '';
                if (tempValues.value[i] != undefined) tempValues.value[i].name = json_decode_name[i].name;
            }
            modal.value = new Modal(document.getElementById('modal_edit_language'));
            modal.value.show();
        });

        return {
            loading,
            id,
            key,
            translation,
            locale,
            locales,
            changeLang,
            updateValue,
            submitHandlerForm
        };
    }
});
